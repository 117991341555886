<template>
  <div class="content">
    <h1 class="title title--big title--indent title--color">Экспорт свободных номеров EYCA</h1>
    <div class="content__party content__party--start">
      <button @click="showSidebar = true"  class="button button--no-indent button--light">
        <img src="@/assets/img/export-icon.svg" alt="">
        <span>Экспортировать номера EYCA</span>
      </button>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      v-if="table.show"
      :data="table.logs"
      :columns="table.columns"
      :options="table.options"
      ref="cardsFreeTable"
      class="table-default">
      <div slot="id" slot-scope="props">
        {{ props.row.id }}
      </div>
      <div slot="file" slot-scope="props">
        <button class="table-default__button" @click="downloadExport(props.row.file_url, `${props.row.file}.csv`)">Скачать</button>
      </div>
      <div slot="success_count" slot-scope="props">
        {{ props.row.success_count }}
      </div>
      <div slot="error_count" slot-scope="props">
        {{ props.row.error_count }}
      </div>
      <div slot="created_at" slot-scope="props">
        {{ props.row.created_at }}
      </div>
      <div slot="updated_at" slot-scope="props">
        {{ props.row.updated_at }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
    <SidebarRight
      title="Заявка на экспорт"
      :class="{'sidebar-manage--full': showSidebar}"
      @close-sidebar="showSidebar = !showSidebar"
    >
      <form @submit.prevent="exportRequest">
        <div class="form-group">
          <label for="count">Количество номеров</label>
          <div class="form-control">
            <input
              id="count"
              type="number"
              class="form-control__input"
              name="count"
              placeholder="Введите количество номеров"
              v-model.number="form.count"
              minlength="1"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="comment">Комментарий</label>
          <div class="form-control">
            <textarea
              id="comment"
              name="comment"
              class="form-control__textarea"
              v-model.trim="form.comment"
              placeholder="Введите комментарий"
            >
            </textarea>
          </div>
        </div>
        <button class="button button--stretched" :disabled="!form.count || !form.comment">Экспортировать номера</button>
      </form>
    </SidebarRight>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
import SidebarRight from '@/components/SidebarRight';

export default {
  name: 'Cards',
  components: { dropdown, SidebarRight },
  data() {
    return {
      table: {
        logs: [],
        columns: ['id', 'file', 'count', 'comment', 'created_at', 'updated_at'],
        options: {
          headings: {
            id: 'ID',
            file: 'Файл экспорта',
            count: 'Количество',
            comment: 'Комментарий',
            created_at: 'Начат',
            updated_at: 'Окончен'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback() {
            return 'table-default__row';
          },
          rowAttributesCallback(row) {
            return { 'data-id': row.id };
          }
        },
        show: false
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: this.$store.state.admin.tableLimit },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      showSidebar: false,
      dataSidebar: {},
      form: {
        count: '',
        comment: ''
      }
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchLogs(1, this.limitSelected.name);
  },
  methods: {
    fetchLogs(page = 1, limit) {
      this.$store.dispatch('admin/cardsFree/GET_EXPORT', { page, limit })
        .then(response => {
          this.table.logs = response.data.results;
          this.table.show = true;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${error.message}`
          });
        });
    },
    exportRequest() {
      const formData = new FormData();
      formData.append('count', this.form.count);
      formData.append('comment', this.form.comment);
      this.$store.dispatch('admin/cardsFree/POST_EXPORT', formData)
        .then(response => {
          this.downloadExport(response.data.file_url, `${response.data.file}.csv`);
          this.form.count = null;
          this.form.comment = '';
          this.showSidebar = false;
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Номера успешно экспортированы'
          });
          this.fetchLogs();
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: `${error.data.count ? error.data.count : error.data}`
          });
        });
    },
    updateLimiter(data) {
      this.$store.commit('admin/changeTableLimit', data.name);
      this.$refs.cardsFreeTable.setLimit(this.$store.state.admin.tableLimit);
      this.fetchLogs(1, this.$store.state.admin.tableLimit);
    },
    clickPaginationCallback(page) {
      this.fetchLogs(page, this.$store.state.admin.tableLimit);
    },
    returnStart() {
      return (this.pagination.page * this.table.logs.length) - (this.table.logs.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.logs.length - 1;
    },
    downloadExport(url, fileName) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const blobURL = URL.createObjectURL(blob);
          if (fileName.length && url.length) {
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = fileName;
            a.style = 'display: none';
            a.click();
          }
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
  }
};
</script>

